export default {
  'APP_ORDER_UPDATE': 'APP_ORDER_UPDATE',
  'APP_REJECT_ORDER': 'APP_REJECT_ORDER',
  'APP_RF_CONFIG_UPDATE': 'APP_RF_CONFIG_UPDATE',
  'APP_PARTIAL_ORDER_REJECTION_CONFIG_UPDATE': 'APP_PARTIAL_ORDER_REJECTION_CONFIG_UPDATE',
  'APP_PRODUCT_IDENTIFIER_UPDATE': 'APP_PRODUCT_IDENTIFIER_UPDATE',
  'APP_ENABLE_TRACKING_PREF_UPDATE': 'APP_ENABLE_TRACKING_PREF_UPDATE',
  'APP_PRINT_PICKLIST_PREF_UPDATE': 'APP_PRINT_PICKLIST_PREF_UPDATE',
  'APP_PRINT_PACKING_SLIP_PREF_UPDATE': 'APP_PRINT_PACKING_SLIP_PREF_UPDATE',
  'APP_SHOW_SHIPPING_ORD_PREF_UPDATE': 'APP_SHOW_SHIPPING_ORD_PREF_UPDATE',
  'APP_COMMERCE_VIEW': 'APP_COMMERCE_VIEW'
}