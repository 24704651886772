export const SN_USER = 'user'
export const USER_TOKEN_CHANGED = SN_USER + '/TOKEN_CHANGED'
export const USER_END_SESSION = SN_USER + '/END_SESSION'
export const USER_INFO_UPDATED = SN_USER + '/INFO_UPDATED'
export const USER_CURRENT_FACILITY_UPDATED = SN_USER + '/CURRENT_FACILITY_UPDATED'
export const USER_INSTANCE_URL_UPDATED = SN_USER + '/INSTANCE_URL_UPDATED' 
export const USER_CURRENT_ECOM_STORE_UPDATED = SN_USER + '/CURRENT_ECOM_STORE_UPDATED'
export const USER_PERMISSIONS_UPDATED = SN_USER + '/PERMISSIONS_UPDATED'
export const USER_PARTIAL_ORDER_REJECTION_CONFIG_UPDATED = SN_USER + '/PARTIAL_ORDER_REJECTION_CONFIG_UPDATED'
export const USER_NOTIFICATIONS_UPDATED = SN_USER + '/NOTIFICATIONS_UPDATED'
export const USER_NOTIFICATIONS_PREFERENCES_UPDATED = SN_USER + '/NOTIFICATIONS_PREFERENCES_UPDATED'
export const USER_ALL_NOTIFICATION_PREFS_UPDATED = SN_USER + '/ALL_NOTIFICATION_PREFS_UPDATED'
export const USER_FIREBASE_DEVICEID_UPDATED = SN_USER + '/FIREBASE_DEVICEID_UPDATED'
export const USER_UNREAD_NOTIFICATIONS_STATUS_UPDATED = SN_USER + '/UNREAD_NOTIFICATIONS_STATUS_UPDATED'
export const USER_BOPIS_PRODUCT_STORE_SETTINGS_UPDATED = SN_USER + '/BOPIS_PRODUCT_STORE_SETTINGS_UPDATED'